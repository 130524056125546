<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>DESAIN ID</span>
                            <el-input v-model="filter.search" placeholder="Search Desain ID" clearable @keypress.enter="onFilter()"/>
                        </div>
                        <div class="filter-item">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable @change="onFilter()">
                                <el-option v-for="(o, i) in listFolder" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>KATEGORI</span>
                            <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter()">
                                <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('product'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('product')"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <div class="my-3 fs-6 p-0 text-gray-600 text-uppercase d-none" v-if="filterActive">
                        <span>Hasil filter</span>
                    </div>
                </div>
                <div class="card-toolbar d-none" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="my-3 fs-6 p-0 text-gray-700 text-capitalize" v-if="pagination.page > 1">
                <span>Halaman {{ pagination.page }}</span>
            </div>
            <div ref="tableRef">
                <div class="item mt-2" v-if="loadingTable">
                    <el-skeleton v-for="i in 10" :key="i" class="item-list me-4 mb-4" style="outline: none;" animated>
                        <template #template>
                            <el-skeleton-item variant="image" style="height: 180px" class="mb-2" />

                            <el-skeleton-item variant="p" style="width: 80%" class="mb-2" />
                            <el-skeleton-item variant="p" style="width: 60%" class="mb-2" />
                            <el-skeleton-item variant="p" style="width: 30%" />
                        </template>
                    </el-skeleton>
                </div>
                <div class="item mt-2" ref="itemRef" v-else>
                    <div v-for="(item, index) in tableData" :key="index" class="item-list rounded me-4 mb-4" style="outline: solid 1px #aaa; outline-offset: -1px; cursor: default;">
                        <div class="item-header text-center">
                            <h6>{{ item.folder }}</h6>
                        </div>
                        <div class="item-image mb-3">
                            <img :src="img_url_desain+item.gambar" />
                        </div>
                        <div class="item-detail mb-3" v-if="item.detail.length > 0">
                            <div class="text-center">Release Di :</div>
                            <div class="text-left">
                                <ul class="ps-5 my-2 fs-8">
                                    <li v-for="detail in item.detail.slice(0, 4)" :key="detail">{{ detail.category + ' - ' + detail.color + ' (' + detail.artikel + ')'}}</li>
                                </ul>
                            </div>
                            <div class="load-more text-center fs-9 text-primary cursor-pointer p-2 " v-if="item.detail.length > 4" @click="loadMore(item.desain_id, item.detail)">Lihat Detail</div>
                        </div>
                        <div v-else class="d-flex justify-content-center align-items-center h-100 py-4 mb-3">
                            <h5 class="text-danger">Belum Release</h5>
                        </div>
                        <div style="height: 20px">
                            <h4 class="text-center mb-4" style="position: absolute; bottom: 0; left:0; right: 0;">{{ item.desain_id }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center my-3">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :page-count="pagination.total_page"
                  :total="pagination.total_page"
                  @next-click="handlePageChange"
                  @prev-click="handlePageChange"
                  @current-change="handlePageChange"
                  v-model:current-page="pagination.page"
                />
            </div>
        </div>

    </div>

    <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
        <inner-image-zoom :src="getZoomImage.src"/>
    </el-dialog>

    <el-dialog
        v-model="loadMoreModal"
        :title="getLoadMore.title"
        :width="'400px'"
    >
        <ul>
            <li v-for="item in getLoadMore.data" :key="item">{{ item.category + ' - ' + item.color + ' (' + item.artikel + ')' }}</li>
        </ul>
    </el-dialog>

</template>

<style type="text/css">
    .item-image img {
        max-height: 150px;
    }

    .item .el-skeleton:hover {
        outline: none !important;
    }

    .item-detail .load-more:hover {
        filter: brightness(0.5);
    }
</style>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: { 
        // Datatable,
        // Field,
        // Form,
        // ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore();

        const [route, router] = [useRoute(), useRouter()]

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const loadingTable = ref(false);
        const tableRef = ref(null)

        const loadMoreModal = ref(false)
        const getLoadMore = ref({
            title: null,
            data: [],
        })

        const listStore = computed(() => store.getters.getListStore)
        const listFolder = computed(() => store.getters.getListRawFolder)
        const listCategory = computed(() => store.getters.getListCategory)

        const tableData = reactive([]);

        const filterActive = ref(false)
        const filter = reactive({
            search: null,
            category_id: null,
            folder_id: null,
        })

        const onFilter = async (type) => {
            filterActive.value = false

            await getData()

            pagination.value.page = 1

            Object.values(filter).every(o => o == null || o.length == 0) ?
                filterActive.value = false :
                filterActive.value = true
        }

        const resetFilter = async (type) => {
            Object.keys(filter).map(k => filter[k] = null) 

            filterActive.value = false

            await getData()
        }

        const loadMore = (title, data) => {
            getLoadMore.value.title = 'Desain ID - ' + title
            getLoadMore.value.data = data

            console.log(data)

            loadMoreModal.value = true
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            await getData()
        }

        const getData = async () => {
            tableData.splice(0)
            loadingTable.value = true

            let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
            let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
            let category = filter.category_id ? '&category=' + filter.category_id : '';
            let search = filter.search ? '&search=' + filter.search : '';

            try {
                await ApiService.get("bankdesain/list" + page + folder + category + search)
                .then(({ data }) => {
                    Object.assign(tableData, data.data.data)

                    pagination.value = data.data.pagination
                })
                .catch(({ response }) => {  
                    if(response) {
                        throw new Error('Error');
                    }
                });
            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loadingTable.value = false
        }

        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.desain_id

            getZoomImage.src = img_url_desain.value + data.gambar
        }

        onBeforeMount(() => {
            setCurrentPageTitle(route.meta.title);
            setCurrentPageBreadcrumbs(route.meta.title, [route.meta.title]);

            store.dispatch(Actions.LIST_STORE);
            store.dispatch(Actions.LIST_RAW_FOLDER);
            store.dispatch(Actions.LIST_CATEGORY);

            getData();
        })

        return {
            formatIDR,
            loadingTable, tableRef, img_url_mockup, img_url_desain,
            onFilter, resetFilter, filter, filterActive, 
            tableData,
            pagination, handlePageChange,
            viewImage, getZoomImage,
            listFolder, listStore, listCategory,
            loadMore, loadMoreModal, getLoadMore,
        }
    }
})
</script>